import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import API from '../Api';
class TopBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			isLogin: false,
			pagesList: [],
			printCash: 0,
			cartlength: 0,
			iscartProgress: false,
			isPcashProgress: false
		};
	}
	componentDidMount() {
		this.CheckLogin();
		this.loadPagesData();
	}
	loadPagesData() {
		API.get(`pages/get`)
			.then((res) => {
				console.log(res);
				this.setState({
					pagesList: res.data.data
				});
			})
			.catch((error) => {
				// Error
				if (error.response) {
					// if (error.response.status === 401) {
					//     localStorage.removeItem("tocken");
					//     this.props.history.push('/');
					// }
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(error.response.data);
					//console.log(error.response.status);
					// console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	}
	CheckLogin() {
		if (localStorage.hasOwnProperty('tocken')) {
			let tocken = localStorage.getItem('tocken');
			const AuthStr = 'Bearer '.concat(tocken);
			API.get(`me`, { headers: { Authorization: AuthStr } })
				.then((res) => {
					console.log(res.data);
					this.setState({ isLogin: true, name: res.data.first_name });

					this.GetUserPrintCash();
					// this.intervalId = setInterval(, 5000);
					setTimeout(() => {
						try {
							this.checkCart();
						} catch (err) {
							console.log('error', err);
						}
					}, 3000);
				})
				.catch((error) => {
					// Error
					if (error.response) {
						if (error.response.status === 401) {
							localStorage.removeItem('tocken');
							window.location.href = '/login';
						}
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					console.log(error.config);
				});
		} else {
			//window.location.href = '/login';
		}
	}
	GetUserPrintCash() {
		let tocken = localStorage.getItem('tocken');
		const AuthStr = 'Bearer '.concat(tocken);
		API.get(`users/user/printcash`, { headers: { Authorization: AuthStr } })
			.then((res) => {
				console.log(res.data);
				this.setState({ printCash: res.data.data });
			})
			.catch((error) => {
				// Error
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.removeItem('tocken');
						window.location.href = '/login';
					}
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	}
	render() {
		return (
			<div id="top-bar">
				<div class="col_half nobottommargin hidden-xs hidden-sm hidden-md clearfix">
					<div class="top-links leftmargin-sm ">
						<ul>
							{this.state.pagesList.filter((x) => x.page_type === '1').map((item, index) => {
								return (
									<li key={index}>
										{item.page_content_type === 3 ? (
											<a target="_blank" rel="noopener noreferrer" href={item.link}>
												{item.title}
											</a>
										) : (
											<Link to={'/information/' + item.seo_name}>{item.title}</Link>
										)}
									</li>
								);
							})}

							<li>
								<Link to={'/faq/faq'} title="FAQ for Printster">
									{' '}
									Faqs
								</Link>
							</li>

							<li>
								<Link to={'/offers'} title="Get the Printster.in Offers">
									Offers
								</Link>
							</li>
							<li>
								<a
									href="mailto:info@printster.in"
									style={{ color: '#FF0000' }}
									title="Printster.in Email Id for Inquery"
								>
									<i class="icon-mail" /> Email : print@printster.in
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="col_half  fright  col_last clearfix  nobottommargin">
					<div class="top-links rightmargin-sm">
						<ul>
							{/* <li>
								<Link to={'/pricecalculator'} title="Printster Rate Calculator">
									{' '}
									Price Calculator
								</Link>
							</li> */}

							<li>
								<Link to={'/track-order'} title="Track Your Order">
									{' '}
									Track Order
								</Link>
							</li>
							<li data-ng-hide="User.customerId">
								<a href="">
									<img src="/Image/wallet.png" alt="user" /> PCash ({' '}
									{parseFloat(this.state.printCash).toFixed(2)} )
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}
export default TopBar;
