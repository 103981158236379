import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
// import userIconImage from '../../public/Image/user.png'
import MessageModel from './MessageModelComponent';
import API from '../Api';

const fright = {
	float: 'right',
	marginTop: '7px'
};

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			categoryList: [],
			isLogin: false,
			printCash: 0,
			cartItem: [],
			pagesList: [],
			Setting: {},
			SearchValue: '',
			ActiveMenu: 0,
			messageModel: false,
			message_id: 0,
			title: 'test',
			description: 'test',
			image: '',
			cartlength: 0,
			iscartProgress: false,
			isPcashProgress: false
		};
	}

	componentDidMount() {
		this.LoadCategoryWithProduct();
		this.CheckLogin();
		this.loadPagesData();
		this.GetSettingData();
		this.GetMessageData();
	}
	SubmitForm = (event) => {
		event.preventDefault();
		this.props.history.push('/search/' + this.state.SearchValue);
	};
	updateForm = (event) => {
		const input = event.target;
		const value = input.value;
		let statusCopy = Object.assign({}, this.state);
		statusCopy.SearchValue = value;
		this.setState(statusCopy);
	};
	GetMessageData() {
		API.get(`sitemessage/get`)
			.then((res) => {
				if (res.data.data.data.length > 0) {
					if (localStorage.hasOwnProperty('message_id')) {
						let id = JSON.parse(localStorage.getItem('message_id'));
						if (res.data.data.data[0].id !== id) {
							let statusCopy = Object.assign({}, this.state);
							statusCopy.title = res.data.data.data[0].title;
							statusCopy.description = res.data.data.data[0].description;
							statusCopy.message_id = res.data.data.data[0].id;
							statusCopy.image = res.data.data.url + res.data.data.data[0].image;
							statusCopy.messageModel = true;
							this.setState(statusCopy);
						}
					} else {
						let statusCopy = Object.assign({}, this.state);
						statusCopy.title = res.data.data.data[0].title;
						statusCopy.description = res.data.data.data[0].description;
						statusCopy.message_id = res.data.data.data[0].id;
						statusCopy.image = res.data.data.url + res.data.data.data[0].image;
						statusCopy.messageModel = true;
						this.setState(statusCopy);
					}
				}
			})
			.catch((error) => {
				// Error
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.removeItem('tocken');
						window.location.href = '/login';
					}
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(error.response.data);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	}
	GetSettingData() {
		var lodercount = 0;
		if (this.state.loader < 0) {
			lodercount = 1;
		} else {
			lodercount = this.state.loader + 1;
		}
		this.setState({ loader: lodercount });
		API.get(`setting/get`)
			.then((res) => {
				lodercount = this.state.loader - 1;
				this.setState({ loader: lodercount }, function() {
					if (res.data.data.length > 0) {
						let statusCopy = Object.assign({}, this.state);
						statusCopy.Setting = res.data.data[0];
						this.setState(statusCopy);
					}
				});
			})
			.catch((error) => {
				// Error
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.removeItem('tocken');
						window.location.href = '/login';
					}
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(error.response.data);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	}
	loadPagesData() {
		API.get(`pages/get`)
			.then((res) => {
				console.log(res);
				this.setState({
					pagesList: res.data.data
				});
			})
			.catch((error) => {
				// Error
				if (error.response) {
					// if (error.response.status === 401) {
					//     localStorage.removeItem("tocken");
					//     this.props.history.push('/');
					// }
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(error.response.data);
					//console.log(error.response.status);
					// console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	}
	checkCart() {
		setTimeout(() => {
			let count = localStorage.getItem('@cartcount');
			if (count != this.state.cartlength) {
				this.GetUserCart();
			}
			this.checkCart();
		}, 1000);
	}
	CheckLogin() {
		if (localStorage.hasOwnProperty('tocken')) {
			let tocken = localStorage.getItem('tocken');
			const AuthStr = 'Bearer '.concat(tocken);
			API.get(`me`, { headers: { Authorization: AuthStr } })
				.then((res) => {
					console.log(res.data);
					this.setState({ isLogin: true, name: res.data.first_name });
					this.GetUserCart();

					// this.intervalId = setInterval(, 5000);
					setTimeout(() => {
						try {
							this.checkCart();
						} catch (err) {
							console.log('error', err);
						}
					}, 3000);
				})
				.catch((error) => {
					// Error
					if (error.response) {
						if (error.response.status === 401) {
							localStorage.removeItem('tocken');
							window.location.href = '/login';
						}
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					console.log(error.config);
				});
		} else {
			//window.location.href = '/login';
		}
	}
	GetUserCart() {
		if (!this.state.iscartProgress) {
			this.setState({ iscartProgress: true });
			let tocken = localStorage.getItem('tocken');
			const AuthStr = 'Bearer '.concat(tocken);
			API.get(`users/cart/get`, { headers: { Authorization: AuthStr } })
				.then((res) => {
					console.log(res.data);
					this.setState(
						{ cartItem: res.data.data, cartlength: res.data.data.length, iscartProgress: false },
						() => {
							localStorage.setItem('@cartcount', JSON.stringify(this.state.cartlength));
						}
					);
				})
				.catch((error) => {
					this.setState({ iscartProgress: false });
					// Error
					if (error.response) {
						if (error.response.status === 401) {
							localStorage.removeItem('tocken');
							window.location.href = '/login';
						}
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					console.log(error.config);
				});
		}
	}

	GetUserPrintCash() {
		let tocken = localStorage.getItem('tocken');
		const AuthStr = 'Bearer '.concat(tocken);
		API.get(`users/user/printcash`, { headers: { Authorization: AuthStr } })
			.then((res) => {
				console.log(res.data);
				this.setState({ printCash: res.data.data });
			})
			.catch((error) => {
				// Error
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.removeItem('tocken');
						window.location.href = '/login';
					}
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	}

	LoadCategoryWithProduct() {
		API.get(`category/active-category-product/get`)
			.then((res) => {
				console.log(res);
				this.setState({ categoryList: res.data.data });
			})
			.catch((error) => {
				// lodercount = this.state.loader - 1;
				//this.setState({ loader: lodercount });
				// Error
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.removeItem('tocken');
						this.props.history.push('/');
					}
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(error.response.data);
					//console.log(error.response.status);
					// console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	}
	MouseOver = (index) => {
		this.setState({ ActiveMenu: index });
	};
	MouseOut = () => {
		this.setState({ ActiveMenu: 0 });
	};
	logout = (e) => {
		if (localStorage.hasOwnProperty('tocken')) {
			let tocken = localStorage.getItem('tocken');
			const AuthStr = 'Bearer '.concat(tocken);
			API.get(`logout`, { headers: { Authorization: AuthStr } })
				.then((res) => {
					console.log(res.data);
					window.location.href = '/';
				})
				.catch((error) => {
					// Error
					if (error.response) {
						if (error.response.status === 401) {
							localStorage.removeItem('tocken');
							window.location.href = '/login';
						}
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					console.log(error.config);
				});
		} else {
			window.location.href = '/';
		}
	};
	UpdateMessageModelState(show) {
		this.setState({ messageModel: show });
		localStorage.setItem('message_id', this.state.message_id);
	}
	render() {
		return (
			<header id="header" className="full-header">
				<MessageModel
					show={this.state.messageModel}
					title={this.state.title}
					description={this.state.description}
					image={this.state.image}
					UpdateShowParent={(show) => this.UpdateMessageModelState(show)}
				/>
				<div className="container">
					<div className="row">
						<nav className="nav navbar navbar-expand-lg navbar-dark bg-dark">
							{/* <a className="navbar-brand" href="#">Always expand</a> */}
							<div id="logo">
								<Link to={'/'} className="standard-logo hidden-xs">
									<img
										src="/Image/logo.png"
										alt="Printster.in | Business Cards, Posters, Leaflets | Digital Printing"
									/>
								</Link>
								<Link to={'/'} className="retina-logo ">
									<img
										src="/Image/logo@2x.png"
										alt="Printster.in | Business Cards, Posters, Leaflets | Digital Printing"
									/>
								</Link>
							</div>

							<button
								className="navbar-toggler d-sm-block d-none"
								type="button"
								data-toggle="collapse"
								data-target="#navbarsExample02"
								aria-controls="navbarsExample02"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon" />
							</button>

							<div className="collapse navbar-collapse" style={fright} id="navbarsExample02">
								<ul>
									<li className="dropdown search-drop">
										<a
											href="#search"
											className=" dropdown-toggle"
											type="button"
											id="dropdownMenuButton"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<img src="/Image/search.png" alt="down" />
										</a>
										<ul
											className="rvdropdown-menu dropdown-menu search"
											aria-labelledby="dropdownMenuButton"
										>
											<li>
												<form onSubmit={this.SubmitForm}>
													<input
														type="text"
														className="form-control"
														placeholder="Search..."
														value={this.state.SearchValue}
														onChange={this.updateForm}
													/>
												</form>
											</li>
										</ul>
									</li>
									<li>
										<Link to={'/'}>
											<img src="/Image/home-icon1.png" alt="Home" />
										</Link>
									</li>

									<li className="parent active all-products">
										<Link to={'/product-all'}> All Products </Link>
										<div className="name">
											<ul className="navbar-nav ml-auto">
												{this.state.categoryList.map((item, index) => {
													return (
														<li
															key={index}
															onMouseOver={() => this.MouseOver(index)}
															onMouseOut={this.MouseOut}
														>
															<Link to={'/category/' + item.seo_name}>{item.name}</Link>
															{item.product.length > 0 ? (
																<ul
																	style={{
																		display:
																			this.state.ActiveMenu === index
																				? 'block'
																				: 'none'
																	}}
																>
																	<li className="Heading"> {item.name}</li>
																	{item.product.length > 0 ? (
																		item.product.map(
																			(productitem, productindex) => {
																				return (
																					<li key={productindex}>
																						<Link
																							to={
																								'/product/' +
																								item.seo_name +
																								'/' +
																								productitem.seo_name
																							}
																						>
																							{productitem.name}
																						</Link>
																					</li>
																				);
																			}
																		)
																	) : (
																		''
																	)}
																</ul>
															) : (
																''
															)}
														</li>
													);
												})}
											</ul>
										</div>
									</li>

									<li>
										<Link to={'/pricecalculator'}>Price calculator</Link>
									</li>

									<li>
										<Link to={'/cart'}>Print Now</Link>
									</li>
									<li>
										<Link to={'/contact'}>Bulk Order</Link>
									</li>

									<li className="dropdown">
										<a
											href="#cart"
											className=" dropdown-toggle"
											type="button"
											id="dropdownMenuButton"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<span className="qty-header">{this.state.cartlength}</span>
											<img src="/Image/cart.png" alt="" />
										</a>

										{this.state.isLogin ? (
											<ul className="dropdown-menu cartDrp" aria-labelledby="dropdownMenuButton">
												<div
													className="top-cart-content ng-scope"
													data-ng-if="totalproducts != '0'"
												>
													{this.state.cartItem.length > 0 ? (
														<div className="top-cart-title">
															<h4>
																Cart Items
																<Link
																	to={'/cart'}
																	className="button button-mini nomargin fright"
																>
																	View Cart
																</Link>
															</h4>
														</div>
													) : null}
													{this.state.cartItem.length > 0 ? (
														<div className="top-cart-items">
															<div
																className="top-cart-item clearfix ng-scope"
																data-ng-repeat="x in files"
															>
																{this.state.cartItem.map((item, index) => {
																	return (
																		<div key={index} className="top-cart-item-desc">
																			<span>{item.filename}</span>
																			<br />
																			<br />
																			<span className="top-cart-item-price ng-binding">
																				<i className="icon-rupee" />{' '}
																				{(item.total / item.copies).toFixed(2)}{' '}
																				x {item.copies} Copies {'       '}
																				<i className="icon-rupee" />{' '}
																				{item.total}
																			</span>
																			<span className="top-cart-item-quantity ng-binding" />
																		</div>
																	);
																})}
															</div>
														</div>
													) : (
														<div className="top-cart-title">
															<h4>Your cart is empty</h4>
														</div>
													)}

													{this.state.cartItem.length > 0 ? (
														<div className="top-cart-action clearfix">
															<span className="fleft top-checkout-price ng-binding">
																<i className="icon-rupee" />{' '}
																{console.log(this.state.cartItem)}
																{parseFloat(
																	this.state.cartItem.reduce(function(cnt, o) {
																		console.log(cnt);
																		return parseFloat(cnt) + parseFloat(o.total);
																	}, 0)
																).toFixed(2)}
															</span>
														</div>
													) : null}
												</div>
											</ul>
										) : (
											''
										)}
									</li>

									{this.state.isLogin ? (
										<li className="login dropdown parent">
											<Link
												to={'/cart'}
												className=" dropdown-toggle"
												type="button"
												id="dropdownMenuButton"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<img src="/Image/user.png" alt="user" /> {this.state.name}
											</Link>
											<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
												<li>
													<Link to={'/profile'} className="dropdown-item">
														User Profile
													</Link>
												</li>
												<li>
													<Link to={'/change-password'} className="dropdown-item">
														Change Password
													</Link>
												</li>
												<li>
													<a href="#FF" onClick={this.logout} className="dropdown-item">
														Logout
													</a>
												</li>
											</ul>
										</li>
									) : (
										<li className="login dropdown parent">
											<Link
												to={'/register'}
												className=" dropdown-toggle"
												type="button"
												id="dropdownMenuButton"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<img src="/Image/user.png" alt="user" /> Login / Signup
											</Link>
										</li>
									)}
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</header>
		);
	}
}
export default withRouter(Header);
