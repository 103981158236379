import React, { Component } from 'react';
class MessageModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			description: '',
			image: '',
			AddAddressModal: false
		};
	}
	componentDidMount() {
		// console.log(this.props.show);
		this.setState({
			title: this.props.title,
			description: this.props.description,
			image: this.props.image,
			AddAddressModal: this.props.show
		});
		this.calculateWidth();
		this.calculateheight();
	}
	componentWillReceiveProps(nextProps) {
		// You don't have to do this check first, but it can help prevent an unneeded render
		if (nextProps.show !== this.state.AddAddressModal) {
			this.setState({
				title: nextProps.title,
				description: nextProps.description,
				image: nextProps.image,
				AddAddressModal: nextProps.show
			});
		}
	}
	closeAddAddress = (event) => {
		this.setState({ AddAddressModal: false });
		this.props.UpdateShowParent(false);
	};
	calculateWidth() {
		console.log('widht', window.innerWidth);
		if (window.innerWidth > 567) {
			return window.innerWidth * 38 / 100;
		} else {
			return 10;
		}
	}
	calculateheight() {
		if (window.innerWidth > 567) {
			return window.innerHeight * 25 / 100;
		} else {
			return 10;
		}
	}

	render() {
		return this.state.AddAddressModal ? (
			<div
				className="modal-content"
				style={{
					top: this.calculateheight(),
					position: 'fixed',
					zIndex: 999,
					left: this.calculateWidth(),
					width: window.innerWidth > 567 ? 500 : '95%'
				}}
			>
				<div className="modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-hidden="true"
						onClick={() => this.closeAddAddress()}
					>
						×
					</button>
					<h4 className="modal-title ng-binding" style={{ textAlign: 'center' }}>
						{this.state.title}
					</h4>
				</div>

				<div className="modal-body modal-padding">
					<div className="col_full  ng-binding">
						<div className="row clearfix">
							<img src={this.state.image} alt="title " style={{ width: '100%' }} />
						</div>
					</div>
					<div className="col_full  ng-binding">
						<div className="row clearfix">
							<div
								className="col-sm-12 col-md-12 col-lg-12"
								dangerouslySetInnerHTML={{ __html: this.state.description }}
							/>
						</div>
					</div>
					<div className="center nomargin">
						<input type="button" className="button" value="Close" onClick={() => this.closeAddAddress()} />
					</div>
				</div>
			</div>
		) : (
			''
		);
	}
}
export default MessageModel;
