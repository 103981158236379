import React, { Suspense, lazy, Component } from 'react';
import Base from './Base';
// import NoFooterBase from './NoFooterBase';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
const Home = lazy(() => import('./Pages/Home'));
const Category = lazy(() => import('./Pages/Category'));
const singup = lazy(() => import('./Pages/singup'));
const Product = lazy(() => import('./Pages/product'));
const ProductAll = lazy(() => import('./Pages/productall'));
const PriceCalculator = lazy(() => import('./Pages/PriceCalculator'));
const PaymentSuccess = lazy(() => import('./Pages/PaymentSuccess'));
const Cart = lazy(() => import('./Pages/cart'));
const CartNew = lazy(() => import('./Pages/cartnew'));
const Profile = lazy(() => import('./Pages/profile'));
const ChangePassword = lazy(() => import('./Pages/changepassword'));
const Address = lazy(() => import('./Pages/address'));
const Notification = lazy(() => import('./Pages/notification'));
const Orders = lazy(() => import('./Pages/orders'));
const TrackOrder = lazy(() => import('./Pages/trackorder'));
const OrderDetails = lazy(() => import('./Pages/orderDetails'));
const CancelRequest = lazy(() => import('./Pages/cancelrequest'));
const Contact = lazy(() => import('./Pages/contact'));
const Faq = lazy(() => import('./Pages/faq'));
const Career = lazy(() => import('./Pages/career'));
const Information = lazy(() => import('./Pages/information'));
const Offers = lazy(() => import('./Pages/offers'));
const OfferProfile = lazy(() => import('./Pages/offerprofile'));
const PrintCashHistory = lazy(() => import('./Pages/printcashhistory'));
const OrderChat = lazy(() => import('./Pages/orderchat'));
const SearchProduct = lazy(() => import('./Pages/serachproduct'));

const waitFor = (Tag) => (props) => <Tag {...props} />;
// const listofPages = [
//   '/login',
//   '/register',
//   '/cart',
// ];
class App extends Component {
	render() {
		console.log(window.location);
		return (
			<Suspense fallback={''}>
				<BrowserRouter>
					<Switch>
						<Base prp="test">
							<Route exact path="/" component={waitFor(Home)} />
							<Route exact path="/cart" component={waitFor(Cart)} />
							<Route exact path="/cart/:category/:product" component={waitFor(Cart)} />
							<Route exact path="/print-cash-history" component={waitFor(PrintCashHistory)} />
							<Route exact path="/offers-profile" component={waitFor(OfferProfile)} />
							<Route exact path="/offers" component={waitFor(Offers)} />
							<Route exact path="/contact" component={waitFor(Contact)} />
							<Route exact path="/order-chat/:id/:no" component={waitFor(OrderChat)} />
							<Route exact path="/information/:seo" component={waitFor(Information)} />
							<Route exact path="/search/:seo" component={waitFor(SearchProduct)} />
							<Route exact path="/faq/:id" component={waitFor(Faq)} />
							<Route exact path="/career" component={waitFor(Career)} />
							<Route exact path="/cartnew" component={waitFor(CartNew)} />
							<Route exact path="/register" component={waitFor(singup)} />
							<Route exact path="/login" component={waitFor(singup)} />
							<Route exact path="/profile" component={waitFor(Profile)} />
							<Route exact path="/change-password" component={waitFor(ChangePassword)} />
							<Route exact path="/address" component={waitFor(Address)} />
							<Route exact path="/return-request/:id" component={waitFor(CancelRequest)} />
							<Route exact path="/notification" component={waitFor(Notification)} />
							<Route exact path="/track-order" component={waitFor(TrackOrder)} />
							<Route exact path="/orders" component={waitFor(Orders)} />
							<Route exact path="/orders-details/:id" component={waitFor(OrderDetails)} />
							<Route exact path="/success/:id" component={waitFor(PaymentSuccess)} />
							<Route exact path="/register/:code" component={waitFor(singup)} />
							<Route path="/category/:cid" component={waitFor(Category)} />
							<Route path="/product/:cid/:pid" component={waitFor(Product)} />
							<Route path="/product-all" component={waitFor(ProductAll)} />
							<Route exact path="/pricecalculator" component={waitFor(PriceCalculator)} />
							<Route exact path="/pricecalculator/:pid" component={waitFor(PriceCalculator)} />
						</Base>
					</Switch>
				</BrowserRouter>
			</Suspense>
		);
	}
}

export default App;
