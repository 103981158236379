import React, { Component } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import TopBar from './components/TopBar';
class Base extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			isLogin: false
		};
	}
	componentDidMount() {}
	UpdateHeader() {
		alert('test');
		// this.refs.headercom.GetUserCart();
		// this.refs.headercom.GetUserPrintCash();
	}
	render() {
		return (
			<div className="clearfix" id="wrapper" style={{ animationDuration: '1.5s', opacity: 1 }}>
				{console.log(this.props)}
				<noscript>
					<iframe
						title="tagmanager"
						src="https://www.googletagmanager.com/ns.html?id=GTM-5WPX5H"
						height="0"
						width="0"
						style={{ display: 'none', visibility: 'hidden' }}
					/>
				</noscript>
				<TopBar />
				<Header ref="headercom" />
				{this.props.children}
				<Footer />
			</div>
		);
	}
}

export default Base;
