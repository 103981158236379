import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import API from '../Api';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			isLogin: false,
			productList: [],
			pagesList: [],
			Setting: {}
		};
	}
	componentDidMount() {
		this.loadCategoryProductData();
		this.loadPagesData();
		this.GetSettingData();
	}
	GetSettingData() {
		var lodercount = 0;
		if (this.state.loader < 0) {
			lodercount = 1;
		} else {
			lodercount = this.state.loader + 1;
		}
		this.setState({ loader: lodercount });
		API.get(`setting/get`)
			.then((res) => {
				lodercount = this.state.loader - 1;
				this.setState({ loader: lodercount }, function () {
					if (res.data.data.length > 0) {
						let statusCopy = Object.assign({}, this.state);
						statusCopy.Setting = res.data.data[0];
						this.setState(statusCopy);
					}
				});
			})
			.catch((error) => {
				// Error
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.removeItem('tocken');
						window.location.href = '/login';
					}
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(error.response.data);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	}
	loadPagesData() {
		API.get(`pages/get`)
			.then((res) => {
				console.log(res);
				this.setState({
					pagesList: res.data.data
				});
			})
			.catch((error) => {
				// Error
				if (error.response) {
					// if (error.response.status === 401) {
					//     localStorage.removeItem("tocken");
					//     this.props.history.push('/');
					// }
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(error.response.data);
					//console.log(error.response.status);
					// console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	}
	loadCategoryProductData() {
		API.get(`category/active-category-product/get`)
			.then((res) => {
				console.log(res);
				this.setState({
					productList: res.data.data
				});
			})
			.catch((error) => {
				// Error
				if (error.response) {
					// if (error.response.status === 401) {
					//     localStorage.removeItem("tocken");
					//     this.props.history.push('/');
					// }
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(error.response.data);
					//console.log(error.response.status);
					// console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	}

	render() {
		return (
			<footer id="footer" className="light ng-scope ng-binding" data-ng-controller="LogCtrl">
				<div className="container">
					<div className="footer-widgets-wrap clearfix">
						<div className="col_two_third">
							<div className="widget clearfix">
								<div className="row">
									<div className="col-md-3 col-sm-6 nobottommargin widget_links">
										<h4>Printster.in</h4>
										<ul>
											{this.state.pagesList
												.filter((x) => x.page_type === '1')
												.map((item, index) => {
													return (
														<li key={index}>
															{item.page_content_type === 3 ? (
																<a
																	target="_blank"
																	rel="noopener noreferrer"
																	href={item.link}
																>
																	{item.title}
																</a>
															) : (
																<Link to={'/information/' + item.seo_name}>
																	{item.title}
																</Link>
															)}
														</li>
													);
												})}
											<li>
												<Link to={'/offers'}>offers</Link>
											</li>
											<li>
												<Link to="/contact">Contact</Link>
											</li>
											<li>
												<a href="/sitemap.html">Sitemap</a>
											</li>
										</ul>
									</div>
									<div className="col-md-3 col-sm-6 nobottommargin widget_links">
										<h4>Help</h4>
										<ul>
											{this.state.pagesList
												.filter((x) => x.page_type === '2')
												.map((item, index) => {
													return (
														<li key={index}>
															{item.page_content_type === 3 ? (
																<a
																	target="_blank"
																	rel="noopener noreferrer"
																	href={item.link}
																>
																	{item.title}
																</a>
															) : (
																<Link to={'/information/' + item.seo_name}>
																	{item.title}
																</Link>
															)}
														</li>
													);
												})}
											<li>
												<Link to="/faq/faq">FAQs</Link>
											</li>
											<li>
												<Link to="/faq/payment-option">Payment Options</Link>
											</li>
											<li>
												<Link to="/career">Career</Link>
											</li>
										</ul>
									</div>

									<div className="col-md-3 col-sm-6 nobottommargin widget_links">
										<h4>Policies</h4>
										<ul>
											{this.state.pagesList
												.filter((x) => x.page_type === '3')
												.map((item, index) => {
													return (
														<li key={index}>
															{item.page_content_type === 3 ? (
																<a
																	target="_blank"
																	rel="noopener noreferrer"
																	href={item.link}
																>
																	{item.title}
																</a>
															) : (
																<Link to={'/information/' + item.seo_name}>
																	{item.title}
																</Link>
															)}
														</li>
													);
												})}
										</ul>
									</div>
									<div className="col-md-3 col-sm-6 nobottommargin widget_links">
										<h4>Stay Connected With Us</h4>
										<div className=" clearfix">
											<a
												href="https://www.facebook.com/printster.in/"
												target="_blank"
												rel="noopener noreferrer"
												className="social-icon si-small si-borderless nobottommargin si-facebook"
												title="Printster.in Facebook Page"
											>
												<i className="icon-facebook" /> <i className="icon-facebook" />
											</a>
											<a
												href="https://twitter.com/printsterin"
												className="social-icon si-small si-borderless nobottommargin si-twitter"
												title="Printster.in Twitter Page"
											>
												<i className="icon-twitter" /> <i className="icon-twitter" />
											</a>
											<a
												href="https://plus.google.com/+PrintsterInOfficial"
												target="_blank"
												rel="noopener noreferrer"
												className="social-icon si-small si-borderless nobottommargin si-google"
											>
												<i className="icon-google" /> <i className="icon-gplus" />
											</a>
											<a
												href="https://www.linkedin.com/company/printster-in"
												target="_blank"
												rel="noopener noreferrer"
												className="social-icon si-small si-borderless nobottommargin si-linkedin"
											>
												<i className="icon-linkedin" /> <i className="icon-pinterest" />
											</a>
										</div>
										<br />
										<h5>Contact</h5>
										<strong>+91-9643230482</strong>
										<br /> Call &amp; Whatsapp<br />
										11:00AM to 8:00PM<br />MON to SAT
										<br />
									</div>
								</div>
							</div>
						</div>
						<div className="col_one_third col_last">
							<div className="widget clearfix" style={{ marginBottom: -20 }}>
								<div className="row">
									{/* <div className="col-md-6 bottommargin-sm">
										<div className="counter counter-small" style={{ color: '#35BBAA' }}>
											<span
												data-from="50"
												data-to="18848092"
												data-refresh-interval="80"
												data-speed="3000"
												data-comma="true"
											/>
										</div>
										<h5 className="nobottommargin">Pages we Printed </h5>
									</div> */}
									<div className="col-md-6 bottommargin-sm">
										<h5 className="nobottommargin text-center">
											We Print With<br />
											<i className="icon-heart3 redcolor pulse infinite animated" /> <br /> in
											India
										</h5>
									</div>
								</div>
							</div>
							<div className="widget subscribe-widget clearfix footerWidget">
								{this.state.Setting.android_url || this.state.Setting.ios_url ? (
									<div className="mobileApplication imageArticle">
										<h5>Download Mobile Application</h5>
										{this.state.Setting.android_url && (
											<a
												href={this.state.Setting.android_url}
												target="_blank"
												rel="noopener noreferrer"
											>
												<img
													src="/Image/android.png"
													style={{ height: 50 }}
													alt="Printster.in Android Mobile Application"
												/>
											</a>
										)}
										{this.state.Setting.ios_url && (
											<a
												href={this.state.Setting.ios_url}
												target="_blank"
												rel="noopener noreferrer"
											>
												<img
													src="/Image/ios.png"
													style={{ height: 50 }}
													alt="Printster.in Android Mobile Application"
												/>
											</a>
										)}
									</div>
								) : null}

								<div className="shiipingPartner imageArticle">
									<h5>Shipping Partner</h5>
									<img src="/Image/dtdc.jpg" alt="DTDC is Printster.in Courier Partner" />
									<img
										src="/Image/Shiprocket.png"
										alt="DTDC is Printster.in Courier Partner"
										style={{ width: '120px' }}
									/>
								</div>
								<div className="paymentPartner imageArticle">
									<h5>
										<strong>Payment Partner</strong>
									</h5>
									{/* <img src="/Image/PayUMoney.png" alt="Make Payment on Printster.in by Payumoney"/>&nbsp;
                        <img src="/Image/Paytm_logo.png" alt="Make Payment on Printster.in by Paytm " style={{height:"25px"}}/> */}
									<img
										src="/Image/Razorpay_logo.png"
										alt="DTDC is Printster.in Courier Partner"
										style={{ width: 100 }}
									/>

									<br />
									<img
										src="/Image/payment-option.png"
										alt="DTDC is Printster.in Courier Partner"
										style={{ width: 200 }}
									/>
									<br />
									{/* <img src="/Image/payment-option.png" alt="Multiple Payment Options on Printster.in"/> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="copyrights">
					<div className="container clearfix">
						<div className="col_full">
							<div className="copyrights-menu copyright-links clearfix">
								Products:
								<br />
								{this.state.productList.map((item, index) => {
									return item.product.map((itemprod, indexprod) => {
										return (
											<Link
												key={itemprod.id}
												to={'/product/' + item.seo_name + '/' + itemprod.seo_name}
											>
												<div>{itemprod.name}</div>
											</Link>
										);
									});
								})}
							</div>
						</div>
						{this.state.Setting.site_name ? (
							<div
								className="col_full"
								dangerouslySetInnerHTML={{ __html: this.state.Setting.abbou_bottom_matter }}
							/>
						) : (
							''
						)}
						<div className="col_half">
							<div>
								<a
									href="https://printster.in/"
									className="standard-logo"
									data-dark-logo="//cdn.printster.in/images/logo-dark.png"
								>
									<img src="/Image/logo.png" alt="Printster.in Logo" />
								</a>
							</div>
						</div>
						<div className="col_half col_last tright">
							Copyrights © 2019 <a href="https://printster.in/">Tech Udyam Solutions Pvt. Ltd.</a>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
export default Footer;
